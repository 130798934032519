import type { JSX, JSXElement } from "solid-js";
import CardLayout from "~/layouts/CardLayout";

const ContentLayout = (props: {
  title?: string;
  justify?: string;
  href?: string;
  as?: "card" | "content";
  alignItems?: string;
  class?: string | undefined;
  children: JSXElement;
}): JSX.Element => {
  return (
    <CardLayout
      as={props.as || "card"}
      title={props.title}
      isPath={props.href !== undefined}
      classList={["content-wrapper", ...String(props.class).split(" ")]}
    >
      <>{props.children}</>
    </CardLayout>
  );
};

export default ContentLayout;
